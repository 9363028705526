<template>
  <div class="pointinwrap">
    <!--p class="pointname"><strong class="rdc">{{userData.memNick}}</strong>님의 포인트 적립 정보입니다.</p-->
    <p class="havepoint">{{$t('front.point.ablePoint')}}</p>
    <ul class="havepointchng">
      <li><img src="@/assets_mobile/img/icon_pointC.svg" />{{thousand(sum ? sum.pointAmt: 0)}}</li>
      <li><a>{{$t('front.board.moneychanges')}}</a></li>
    </ul>
    <ul class="havepointwrap">
      <li><em>{{$t('front.point.monthPoint')}}</em><p><span>{{thousand(sum ? sum.thisMonthPoint : 0)}}</span>P</p></li>
      <li><em>{{$t('front.point.allPoint')}}</em><p><span>{{thousand(sum ? sum.totalInPoint : 0)}}</span>P</p></li>
      <li><em>{{$t('front.point.allUsePoint')}}</em><p><span>{{thousand(sum ? sum.totalOutPoint : 0)}}</span>P</p></li>
    </ul>
    <span class="prvpoint">{{$t('front.point.prevMonthPoint')}} : {{thousand(sum ? sum.preMonthPoint: 0)}}P</span>

    <!--ul class="pointinlist">
      <li>
        <span>보유 포인트</span>
        <span class="pt"><em class="orc">{{thousand(sum ? sum.pointAmt: 0)}}</em>P</span>
      </li>
      <li>
        <span>당월 적립포인트</span>
        <span class="pt"><em class="orc">{{thousand(sum ? sum.thisMonthPoint : 0)}}</em>P</span>
      </li>
    </ul>
    <ul class="pointinlist">
      <li>
        <span>전월 적립포인트</span>
        <span class="pt"><em class="orc">{{thousand(sum ? sum.preMonthPoint: 0)}}</em>P</span>
      </li>
      <li>
        <span>총 누적포인트</span>
        <span class="pt"><em class="orc">{{thousand(sum ? sum.totalInPoint : 0)}}</em>P</span>
      </li>
      <li>
        <span>총 사용포인트</span>
        <span class="pt"><em class="orc">{{thousand(sum ? sum.totalOutPoint : 0)}}</em>P</span>
      </li>
    </ul-->
  </div>

  <date-filter-mobile @search="loadList"
                      @update="onChangeDate"
  />

  <div class="gamebetwrap mb100">
    <template v-if="list.length > 0">
      <div class="gamebetlist" v-for="item in list" v-bind:key="item.gameIdx">
        <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
        <ul class="gamebetdetail useaddpointwrap">
          <li class="useaddpoint addp"><!-- usep addp -->
            <em>{{$t('front.board.accrual')}}</em><!-- 사용 적립 -->
            <span><em>{{thousand(item.point_inAmt)}}</em>P</span>
          </li>
          <li class="useaddpointcon">
            <span>{{dateFormatAll(item.regDt)}}</span>
            <span>{{item.codeName}}</span>
            <!--span>아이디 : {{item.memId}}</span>
            <span>퍼센트 : {{item.pointRate}}%</span>
            <span>보너스퍼센트 : {{item.pointBonusRate}}%</span>
            <span>베팅금액 : {{thousand(item.betAmt)}}</span>
            <span>적립포인트 : <span class="bbl">{{thousand(item.point_inAmt)}}P</span></span-->
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <div class="gamebetlist">
        <ul class="gamebetdetail">
          {{$t('front.common.notFoundList')}}
        </ul>
      </div>
    </template>
  </div>

  <pagination-mobile :pageNum="pageInfo.page"
                     :pageSize="pageInfo.count_per_list"
                     :totalCount="pageInfo.tatal_list_count"
                     @goToPage="loadList"/>
</template>

<script>
import pointList from '@/views/member/mypage/point/list'

import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'

export default {
  name: 'mobilePointList',
  components: {
    DateFilterMobile,
    PaginationMobile
  },
  mixins: [
    pointList
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
